<template>
  <div>
    <div v-if="toType && toType == 'App\\Models\\User'">
      <b
        v-show="completedBy !== toId"
        :title="completedByTitle"
        class="processed-by not-same"
        data-test-id="completed-by-user"
        >{{ completedByName }}</b
      >
    </div>
    <div v-else>
      <b
        v-if="
          to.trip_type == 'Roundtrip' &&
          ((fromType === 'App\\Models\\User' &&
            from.id !== completedByUser?.id) ||
            (createdBy !== completedBy && fromType === 'App\\Models\\Room'))
        "
        :title="completedByTitle"
        :class="{
          'not-same': !to.user_ids_assigned.includes(completedBy)
        }"
        class="processed-by"
        >{{ completedByName }}</b
      >
      <b v-else>
        <div v-if="endedWith && endedWith === 'autopass'" class="processed-by">
          Auto Pass
        </div>
        <div v-else>
          <div
            v-if="to && to.trip_type !== 'Roundtrip'"
            :title="completedByTitle"
            :class="{
              'not-same': !to.user_ids_assigned.includes(completedBy)
            }"
            class="processed-by"
          >
            {{ completedByName }}
          </div>
        </div>
      </b>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toType: {
      type: String,
      default: null
    },
    completedBy: {
      type: Number,
      default: null
    },
    toId: {
      type: Number,
      default: null
    },
    completedByUser: {
      type: Object,
      default: null
    },
    to: {
      type: Object,
      default: null
    },
    fromType: {
      type: String,
      default: null
    },
    from: {
      type: Object,
      default: null
    },
    createdBy: {
      type: Number,
      default: null
    },
    endedWith: {
      type: String,
      default: null
    },
    completedByTitle: {
      type: String,
      default: null
    },
    completedByName: {
      type: String,
      default: null
    }
  }
}
</script>
