<template>
  <div class="d-flex align-items-center tabs-container">
    <div
      data-test-id="dashboard-future-apt-tab"
      v-for="(tab, index) in availableTableTabs"
      :key="index"
      class="table-tab"
      :class="[tab.class, { active: tab.value === activePassType }]"
      @click="setActivePassType(tab.value)"
    >
      <span v-if="tab.icon" class="tab-icon" :class="tab.icon"></span>
      <span v-html="tab.label"></span>
      <span v-if="tab.count && stats && stats[tab.count]" class="counter-badge"
        >{{ stats[tab.count] }}
      </span>
    </div>
  </div>
</template>

<script>
import passConstants from "@/constants/passConstants"
import { reactive, computed, onMounted, watch } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardTabsOld",
  setup() {
    const store = useStore()

    const state = reactive({
      tableTabs: [
        {
          label: "<b>My<br></b> Passes",
          icon: "flaticon-dashboard_user",
          type: "pass",
          value: "my",
          class: "pass-tabs"
        },
        {
          label: "<b>All<br></b> Passes",
          icon: "flaticon-dashboard_customer",
          type: "pass",
          value: "all",
          class: "pass-tabs"
        },
        {
          label: "Today's Appointments",
          type: "appointments",
          value: "appointments_today",
          class: "purple d-none d-sm-flex pass-tabs",
          count: "todayAppointmentsCount"
        },
        {
          label: "Today's Awaiting Confirmation",
          type: "appointments",
          value: "appointments_awaiting",
          class: "red d-none d-sm-flex pass-tabs",
          count: "awaitingTodayAppointmentsCount"
        },
        {
          label: "Future Awaiting Confirmation",
          type: "appointments",
          value: "appointments_future_awaiting",
          class: "green d-none d-sm-flex pass-tabs",
          count: "futureAppointmentsCount"
        }
      ]
    })

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )
    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )
    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )
    const appTodays = computed(
      () => store.getters["dashboardCounters/appTodays"]
    )
    const appTodaysWaiting = computed(
      () => store.getters["dashboardCounters/appTodaysWaiting"]
    )
    const appFeatureWaiting = computed(
      () => store.getters["dashboardCounters/appFeatureWaiting"]
    )
    const aptPassesCounters = computed(
      () => store.getters["dashboardCounters/aptPassesCounters"]
    )
    const globalTime = computed(() => store.getters["layout/globalTime"])
    const isUserInTransparency = computed(
      () => store.getters["authentication/isUserInTransparency"]
    )

    onMounted(() => {
      const type = store.getters["dashboardTable/getType"]
      if (
        !window.localStorage.getItem(
          passConstants.LOCAL_STORAGE_KEYS.DASHBOARD_PASS_TYPE
        ) ||
        type == "appointment" ||
        (!isActiveAppoinmentModule.value && type !== "my" && type !== "all")
      ) {
        window.localStorage.setItem(
          passConstants.LOCAL_STORAGE_KEYS.DASHBOARD_PASS_TYPE,
          "my"
        )
        store.commit("dashboardTable/UPDATE_TYPE", "my")
      }
    })

    const stats = computed(() => {
      return {
        todayAppointmentsCount: appTodays.value,
        awaitingTodayAppointmentsCount: appTodaysWaiting.value,
        futureAppointmentsCount: appFeatureWaiting.value
      }
    })

    const availableTableTabs = computed(() => {
      const withoutType = isActiveAppoinmentModule.value ? null : "appointments"
      const withoutValues = activeSchoolHasAppoitmentPasses.value
        ? []
        : ["appointments_awaiting", "appointments_future_awaiting"]
      return state.tableTabs.filter(
        (tab) => tab.type != withoutType && !withoutValues.includes(tab.value)
      )
    })

    watch(
      () => globalTime.value,
      () => {
        store.commit(
          "dashboardCounters/UPDATE_APP_COUNTERS",
          aptPassesCounters.value
        )
      }
    )

    const setActivePassType = (type) => {
      store.commit("dashboardTable/UPDATE_TYPE", type)
      //set deafult value to "active" if user is not in transparency
      if (!isUserInTransparency.value && type == "all") {
        store.commit("dashboardTable/UPDATE_STATUS", "active")
      }
    }

    return {
      state,
      activePassType,
      stats,
      availableTableTabs,
      setActivePassType
    }
  }
}
</script>
