<template>
  <div>
    <div v-if="isAutoPass" class="processed-by">Auto Pass</div>
    <div v-else>
      <b class="processed-by" :class="{ 'not-same': userRoleId !== 1 }">
        kiosk
      </b>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAutoPass: {
      type: Boolean,
      required: true
    },
    userRoleId: {
      type: Number,
      required: true
    }
  }
}
</script>
