<template>
  <div class="mt-1">
    <div v-if="fromType === 'App\\Models\\User'">
      <b
        v-show="fromId !== approvedByUserId"
        :title="approvedByTitle"
        class="processed-by not-same"
        data-test-id="approved-by-user"
      >
        {{ approvedByName }}
      </b>
    </div>
    <div v-else>
      <b
        :title="approvedByTitle"
        :class="{
          'not-same': !assignedUserIds.includes(approvedById)
        }"
        class="processed-by"
      >
        {{ approvedByName }}
      </b>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fromType: {
      type: String,
      default: null
    },
    fromId: {
      type: [String, Number],
      default: null
    },
    approvedByUserId: {
      type: [String, Number],
      default: null
    },
    approvedById: {
      type: [String, Number],
      default: null
    },
    approvedByUser: {
      type: Object,
      default: null,
      validator: (value) => {
        return value?.first_name !== undefined && value?.last_name !== undefined
      }
    },
    assignedUserIds: {
      type: Array,
      default: () => []
    },
    approvedByTitle: {
      type: String,
      default: null
    },
    approvedByName: {
      type: String,
      default: null
    }
  }
}
</script>
