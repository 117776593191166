<template>
  <div>
    <div
      v-if="
        child &&
        fromKiosk &&
        approvedWith !== 'autopass' &&
        endedWith !== 'autopass'
      "
    >
      <b
        v-if="
          (fromType == 'App\\Models\\User' &&
            !fromKiosk.room &&
            fromKiosk.user.id === from.id) ||
          (fromType == 'App\\Models\\Room' &&
            fromKiosk.room &&
            fromKiosk.room.id === from.id)
        "
        class="processed-by"
      >
        kiosk</b
      >
      <b v-else>
        <span
          v-if="
            (fromKiosk &&
              fromKiosk.room &&
              !fromKiosk.user &&
              fromKiosk.room.id === to.id) ||
            (fromKiosk &&
              fromKiosk.user &&
              !fromKiosk.room &&
              fromKiosk.user.id === to.id) ||
            (fromKiosk &&
              fromKiosk.room &&
              fromKiosk.user &&
              fromKiosk.room.id === to.id)
          "
          class="processed-by"
        >
          kiosk
        </span>
        <span v-else class="processed-by not-same">kiosk</span>
      </b>
    </div>
    <div v-else>
      <div
        v-if="
          child && approvedWith == 'autopass' && fromKiosk && fromKiosk.room
        "
      >
        <span
          v-if="
            (fromKiosk &&
              fromKiosk.room &&
              !fromKiosk.user &&
              fromKiosk.room.id === to.id) ||
            (fromKiosk &&
              fromKiosk.user &&
              !fromKiosk.room &&
              fromKiosk.user.id === to.id) ||
            (fromKiosk &&
              fromKiosk.room &&
              fromKiosk.user &&
              fromKiosk.room.id === to.id)
          "
          class="processed-by"
        >
          kiosk
        </span>
        <span v-else class="processed-by not-same">kiosk</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    child: {
      type: Object,
      required: true
    },
    fromKiosk: {
      type: Object,
      required: true
    },
    approvedWith: {
      type: String,
      required: true
    },
    endedWith: {
      type: String,
      required: true
    },
    fromType: {
      type: String,
      required: true
    },
    from: {
      type: Object,
      required: true
    },
    to: {
      type: Object,
      required: true
    }
  }
}
</script>
