<template>
  <div class="maintenance-mode">
    Pass is currently undergoing maintenance and is temporarily unavailable. You
    will be redirected to the login screen shortly.
  </div>
</template>

<script>
export default {
  name: "MaintenanceMode"
}
</script>

<style scoped>
.maintenance-mode {
  text-align: center;
  font-size: 18px;
  padding: 0 10px 20px 10px;
}
</style>
