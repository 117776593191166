<template>
  <div>
    <div v-if="toType && toType == 'App\\Models\\User'">
      <b
        v-show="completedBy !== toId"
        :title="completedByTitle"
        class="processed-by not-same"
        >{{ completedByName }}</b
      >
    </div>
    <div v-else>
      <b
        :title="completedByTitle"
        :class="{
          'not-same': !to.user_ids_assigned.includes(completedBy)
        }"
        class="processed-by"
        >{{ completedByName }}</b
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toType: {
      type: String,
      default: null
    },
    completedBy: {
      type: [String, Number],
      default: null
    },
    toId: {
      type: [String, Number],
      default: null
    },
    completedByUser: {
      type: Object,
      default: null,
      validator: (value) => {
        return value?.first_name !== undefined && value?.last_name !== undefined
      }
    },
    to: {
      type: Object,
      default: null
    },
    completedByTitle: {
      type: String,
      default: null
    },
    completedByName: {
      type: String,
      default: null
    }
  }
}
</script>
