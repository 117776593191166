<template>
  <div class="custom-select-datepicker">
    <CustomSelect
      v-if="!state.showDatepicker"
      v-model="state.selectedRange"
      @update:model-value="selectOption"
      :filterable="false"
      :searchable="false"
      :close-on-select="true"
      :options="dateRanges"
      placeholder="Select Date Range"
      class="custom-select-datepicker-date-select"
      :append-icon="'ri-calendar-line'"
    >
    </CustomSelect>

    <CustomDatePicker
      v-if="state.showDatepicker"
      :is-range="true"
      :min-date="state.minSchoolYearDate ? state.minSchoolYearDate : minDate"
      :max-date="state.maxSchoolYearDate ? state.maxSchoolYearDate : maxDate"
      :placeholder="'Select Date Range'"
      :is-custom-select="state.showDatepicker"
      v-model="state.selectedRangeCalendar"
      @update:model-value="onCloseCalendar"
    >
    </CustomDatePicker>
  </div>
</template>
<script>
import { reactive, computed, watch } from "vue"
import helpers from "@/helpers/index.js"
import moment from "moment-timezone"
import CustomSelect from "./CustomSelect.vue"
import CustomDatePicker from "./CustomDatePicker.vue"

export default {
  components: { CustomSelect, CustomDatePicker },
  emits: ["update:modelValue"],
  props: {
    dateRanges: Array,
    modelValue: [String, Object],
    limitMaxDate: {
      type: Boolean,
      default: false
    },
    shouldPersistValue: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      showDatepicker: false,
      selectedRange: props.dateRanges ? props.dateRanges[0].range : "",
      selectedRangeCalendar: null,
      startDate: null,
      endDate: null,
      maxSchoolYearDate: null,
      minSchoolYearDate: null
    })

    const minDate = computed(() => {
      return helpers.minSchoolYearDate()
    })

    const maxDate = computed(() => {
      const today = moment()
      return props.limitMaxDate
        ? new Date(helpers.currTzDate(today))
        : helpers.maxSchoolYearDate()
    })

    watch(
      () => props.modelValue,
      (modelValueRange) => {
        if (modelValueRange) {
          state.selectedRange = modelValueRange.range
            ? modelValueRange.range
            : modelValueRange
          if (modelValueRange.id === "custom") {
            state.selectedRangeCalendar = {
              start: modelValueRange.startDate
                ? modelValueRange.startDate
                : null,
              end: modelValueRange.endDate ? modelValueRange.endDate : null
            }
            if (
              modelValueRange.current_school_year &&
              modelValueRange.current_school_year != modelValueRange.range
            ) {
              state.showDatepicker = true
            } else {
              state.showDatepicker = false
            }
            state.minSchoolYearDate = modelValueRange.selected_school_year
              ? modelValueRange.selected_school_year.split(" - ")[0]
              : null
            state.maxSchoolYearDate = modelValueRange.selected_school_year
              ? modelValueRange.selected_school_year.split(" - ")[1]
              : null
            state.startDate = modelValueRange.startDate
              ? modelValueRange.startDate
              : null
            state.endDate = modelValueRange.endDate
              ? modelValueRange.endDate
              : null
          }
        }
      }
    )

    const selectOption = () => {
      if (state.selectedRange.id === "custom") {
        state.startDate = state.selectedRange.startDate
          ? state.selectedRange.startDate
          : null
        state.endDate = state.selectedRange.endDate
          ? state.selectedRange.endDate
          : null
        state.showDatepicker = true
        if (props.shouldPersistValue) {
          state.selectedRangeCalendar = {
            start: moment(props.modelValue.split(" - ")[0], "MM/DD/YYYY")._d,
            end: moment(props.modelValue.split(" - ")[1], "MM/DD/YYYY")._d
          }
        } else {
          state.selectedRangeCalendar = {
            start: moment()._d,
            end: moment()._d
          }
        }
      } else {
        setRange(false)
      }
    }

    const setRange = (isFromCalendar) => {
      let value

      if (isFromCalendar) {
        const range =
          helpers.currTzDate(state.selectedRangeCalendar.start) +
          " - " +
          helpers.currTzDate(state.selectedRangeCalendar.end)

        value = range
        state.selectedRange = range
        if (
          moment(helpers.currTzDate(state.selectedRangeCalendar.start)).isAfter(
            helpers.currTzDate(helpers.minSchoolYearDate())
          ) &&
          moment(helpers.currTzDate(state.selectedRangeCalendar.end)).isBefore(
            helpers.currTzDate(helpers.maxSchoolYearDate())
          )
        ) {
          state.showDatepicker = false
        } else {
          state.showDatepicker = true
        }
      } else {
        if (state.selectedRange.id !== "custom") {
          value = state.selectedRange.range
        } else {
          value = state.selectedRange
        }
        state.selectedRange = state.selectedRange.range
      }

      emit("update:modelValue", value)
    }
    const onCloseCalendar = () => {
      setRange(true)
    }

    return {
      state,
      minDate,
      maxDate,
      setRange,
      onCloseCalendar,
      selectOption
    }
  }
}
</script>
