<template>
  <span v-if="pass" class="status-flag" :style="'color:' + passFlagColor"
    ><i class="flaticon-flag-2"
  /></span>
</template>

<script>
import { computed } from "vue"
export default {
  name: "PassFlag",
  props: {
    pass: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const passFlagColor = computed(() => {
      if (props.pass && props.pass.badge_flags) {
        if (props.pass.type === "SLP") {
          return "transparent"
        }
        if (props.pass.badge_flags.system_completed) {
          return "#DF3C3B"
        }
        if (props.pass.badge_flags.min_time) {
          return "#F4EA65"
        }
        if (props.pass.badge_flags.extended) {
          return "#672C91"
        }
      }
      return "transparent"
    })

    return { passFlagColor }
  }
}
</script>

<style></style>
