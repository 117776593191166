<template>
  <div v-if="pass" class="v3-in-out-pass-times">
    <div v-if="section === 'approved_at'">
      <ApprovedByUser
        v-if="showApprovedByUser"
        :from-type="pass.from_type"
        :from-id="pass.from.id"
        :approved-by-user-id="pass.approved_by_user.id"
        :approved-by-id="pass.approved_by"
        :approved-by-user="pass.approved_by_user"
        :assigned-user-ids="pass.from.user_ids_assigned"
        :approved-by-title="
          pass.approved_by_user.first_name +
          ' ' +
          pass.approved_by_user.last_name
        "
        :approved-by-name="approved_by_user"
      />
      <KioskApprovedBy
        v-if="showKioskApprovedBy"
        :is-auto-pass="pass.approved_with === 'autopass'"
        :user-role-id="pass.approved_by_user.role_id"
      />
      <AutoPassApprovedBy v-if="showAutoPassApprovedBy" />
    </div>

    <div v-if="section === 'completed_at'">
      <CompletedByUser
        v-if="showCompletedByUser"
        :to_type="pass.to_type"
        :completed_by="pass.completed_by"
        :to_id="pass.to_id"
        :completed_by_user="pass.completed_by_user"
        :to="pass.to"
        :from_type="pass.from_type"
        :from="pass.from"
        :created_by="pass.created_by"
        :ended_with="pass.ended_with"
        :completed-by-title="
          pass.completed_by_user.first_name +
          ' ' +
          pass.completed_by_user.last_name
        "
        :completed-by-name="completed_by"
      />
      <KioskCompleted
        v-if="pass.to_kiosk"
        :ended-with="pass.ended_with"
        :to-type="pass.to_type"
        :to-kiosk="pass.to_kiosk"
        :to-id="pass.to_id"
        :to="pass.to"
        :created-by-kiosk="pass.created_by_kiosk"
        :from="pass.from"
      />
      <AutoPassCompleted v-if="showAutoPass" />
      <CheckInLabel v-if="showCheckIn" />
      <SystemEndedLabel v-if="showSystemEndedCompletedAt" />
    </div>

    <div v-if="section === 'child_approved_at'">
      <ChildApprovedByUser
        v-if="showChildApprovedByUser"
        :from-type="pass.child.from_type"
        :from-id="pass.child.from.id"
        :approved-by-user-id="pass.child.approved_by_user.id"
        :approved-by-id="pass.child.approved_by"
        :approved-by-user="pass.child.approved_by_user"
        :assigned-user-ids="pass.child.from.user_ids_assigned"
        :approved-by-title="
          pass.child.approved_by_user.first_name +
          ' ' +
          pass.child.approved_by_user.last_name
        "
        :approved-by-name="child_approved_by_user"
      />
      <AutoPassApprovedBy v-if="showChildAutoPassApprovedBy" />
      <KioskChildApprovedAt
        v-if="showChildKioskApprovedBy"
        :child="pass.child"
        :from-kiosk="pass.child.from_kiosk"
        :approved-with="pass.child.approved_with"
        :ended-with="pass.child.ended_with"
        :from-type="pass.child.from_type"
        :from="pass.child.from"
        :to="pass.child.to"
      />
      <SystemEndedLabel v-if="showChildApprovedAtSystemEnded" />
    </div>

    <div v-if="section === 'child_completed_at'">
      <ChildCompletedByUser
        v-if="showChildCompletedByUser"
        :to-type="pass.child.to_type"
        :completed-by="pass.child.completed_by"
        :to-id="pass.child.to_id"
        :completed-by-user="pass.child.completed_by_user"
        :to="pass.child.to"
        :completed-by-title="
          pass.child.completed_by_user.first_name +
          ' ' +
          pass.child.completed_by_user.last_name
        "
        :completed-by-name="child_completed_by_user"
      />
      <ChildCompletedAtKiosk
        :child="pass.child"
        :from-kiosk="pass.child.from_kiosk"
        :approved-with="pass.child.approved_with"
        :ended-with="pass.child.ended_with"
        :from-type="pass.child.from_type"
        :from="pass.child.from"
        :to="pass.child.to"
      />
      <AutoPassCompleted v-if="showChildAutoPassCompleted" />
      <SystemEndedLabel v-if="showChildCompletedBySystemEnded" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import helpers from "@/helpers/index.js"
import ApprovedByUser from "./Labels/ApprovedByUser.vue"
import KioskApprovedBy from "./Labels/KioskApprovedBy.vue"
import AutoPassApprovedBy from "./Labels/AutoPassApprovedBy.vue"
import CompletedByUser from "./Labels/CompletedByUser.vue"
import KioskCompleted from "./Labels/KioskCompleted.vue"
import AutoPassCompleted from "./Labels/AutoPassCompleted.vue"
import CheckInLabel from "./Labels/CheckInLabel.vue"
import SystemEndedLabel from "./Labels/SystemEndedLabel.vue"
import KioskChildApprovedAt from "./Labels/KioskChildApprovedAt.vue"
import ChildApprovedByUser from "./Labels/ChildApprovedByUser.vue"
import ChildCompletedAtKiosk from "./Labels/ChildCompletedAtKiosk.vue"
import ChildCompletedByUser from "./Labels/ChildCompletedByUser.vue"

export default {
  name: "InOutColumn",
  components: {
    ApprovedByUser,
    KioskApprovedBy,
    AutoPassApprovedBy,
    CompletedByUser,
    KioskCompleted,
    AutoPassCompleted,
    CheckInLabel,
    SystemEndedLabel,
    KioskChildApprovedAt,
    ChildApprovedByUser,
    ChildCompletedAtKiosk,
    ChildCompletedByUser
  },
  props: {
    pass: {
      type: Object,
      required: true
    },
    section: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const isAutoApprove = computed(() => {
      if (props.pass && props.pass.created_at && props.pass.approved_at) {
        return (
          helpers.date(props.pass.created_at).format("DD-MM-YYYY HH:mm:ss") ===
          helpers.date(props.pass.approved_at).format("DD-MM-YYYY HH:mm:ss")
        )
      }
      return false
    })

    const approved_by_user = computed(() => {
      const fullName =
        props.pass.approved_by_user.first_name +
        " " +
        props.pass.approved_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const completed_by = computed(() => {
      const fullName =
        props.pass.completed_by_user.first_name +
        " " +
        props.pass.completed_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const child_approved_by_user = computed(() => {
      const fullName =
        props.pass.child.approved_by_user.first_name +
        " " +
        props.pass.child.approved_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const child_completed_by_user = computed(() => {
      const fullName =
        props.pass.child.completed_by_user.first_name +
        " " +
        props.pass.child.completed_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const isSectionTimeAvailable = computed(() => {
      switch (props.section) {
        case "approved_at":
          return props.pass.approved_at
        case "completed_at":
          return props.pass.completed_at
        case "child_approved_at":
          return props.pass.child?.approved_at
        case "child_completed_at":
          return props.pass.child?.completed_at

        default:
          return false
      }
    })

    // Approved By
    const showApprovedByUser = computed(() => {
      return (
        (props.pass.approved_by_user &&
          props.pass.from &&
          props.pass.type !== "KSK" &&
          props.pass.approved_with !== "autopass") ||
        (props.pass.approved_by_user &&
          props.pass.from &&
          props.pass.approved_with !== "autopass")
      )
    })

    const showKioskApprovedBy = computed(() => {
      return (
        props.pass.from_kiosk &&
        props.pass.approved_by &&
        props.pass.approved_by !== props.pass.from_kiosk.user.id &&
        props.pass.type === "KSK"
      )
    })

    const showAutoPassApprovedBy = computed(() => {
      return (
        props.pass.type !== "KSK" &&
        props.pass.approved_with &&
        props.pass.approved_with === "autopass"
      )
    })

    // Completed By
    const isUserType = computed(
      () => props.pass.to_type === "App\\Models\\User"
    )

    const isDifferentUser = computed(
      () => props.pass.completed_by !== props.pass.to_id
    )

    const isRoundtripCase = computed(
      () =>
        props.pass.to.trip_type === "Roundtrip" &&
        ((props.pass.from_type === "App\\Models\\User" &&
          props.pass.from.id !== props.pass.completed_by_user.id) ||
          (props.pass.created_by !== props.pass.completed_by &&
            props.pass.from_type === "App\\Models\\Room"))
    )

    const isAutoPass = computed(() => props.pass.ended_with === "autopass")

    const isNonRoundtrip = computed(
      () => props.pass.to && props.pass.to.trip_type !== "Roundtrip"
    )

    const isAssignedUser = computed(() =>
      props.pass.to.user_ids_assigned.includes(props.pass.completed_by)
    )

    const completedByTitle = computed(
      () =>
        `${props.pass.completed_by_user.first_name} ${props.pass.completed_by_user.last_name}`
    )

    const showCompletedByUser = computed(() => {
      return props.pass.completed_by && props.pass.to && !props.pass.ended_with
    })

    const showAutoPass = computed(() => {
      return (
        props.pass.ended_with &&
        props.pass.ended_with === "autopass" &&
        props.pass.to_kiosk
      )
    })

    const showCheckIn = computed(() => {
      return props.pass.ended_with && props.pass.ended_with === "checkin"
    })

    const showSystemEndedCompletedAt = computed(() => {
      return (
        (!props.pass.child && props.pass.badge_flags.system_completed) ||
        (!props.pass.child &&
          props.pass.badge_flags.extended &&
          props.pass.badge_flags.ended)
      )
    })

    // Child Approved By
    const showChildApprovedByUser = computed(
      () =>
        props.pass.child &&
        props.pass.child.approved_by &&
        props.pass.child.from &&
        !props.pass.child.approved_with
    )

    const showChildAutoPassApprovedBy = computed(
      () =>
        props.pass.child &&
        props.pass.child.approved_with === "autopass" &&
        props.pass.child.from_kiosk &&
        !props.pass.child.from_kiosk.room
    )

    const showChildKioskApprovedBy = computed(
      () =>
        props.pass.child &&
        props.pass.child.from_kiosk &&
        props.pass.child.approved_with !== "autopass" &&
        props.pass.child.ended_with !== "autopass"
    )

    const showChildApprovedAtSystemEnded = computed(
      () =>
        (props.pass.child &&
          !props.pass.child.approved_by &&
          props.pass.badge_flags.system_completed) ||
        (props.pass.child &&
          props.pass.badge_flags.extended &&
          props.pass.badge_flags.ended &&
          !props.pass.child.approved_by)
    )

    // Child Completed By
    const showChildCompletedByUser = computed(
      () =>
        props.pass.child &&
        props.pass.child.completed_by &&
        props.pass.child.to &&
        !props.pass.child.ended_with
    )

    const showChildAutoPassCompleted = computed(
      () =>
        props.pass.child &&
        props.pass.child.ended_with === "autopass" &&
        props.pass.child.to_kiosk &&
        !props.pass.child.to_kiosk.room
    )

    const showChildCompletedBySystemEnded = computed(
      () =>
        (props.pass.child &&
          !props.pass.child.completed_by &&
          props.pass.child.approved_by &&
          props.pass.badge_flags.system_completed) ||
        (props.pass.child &&
          props.pass.badge_flags.extended &&
          props.pass.badge_flags.ended &&
          !props.pass.child.completed_by &&
          props.pass.child.approved_by)
    )

    return {
      isAutoApprove,
      approved_by_user,
      completed_by,
      child_approved_by_user,
      child_completed_by_user,
      isSectionTimeAvailable,
      showApprovedByUser,
      showKioskApprovedBy,
      showAutoPassApprovedBy,
      isUserType,
      isDifferentUser,
      isRoundtripCase,
      isAutoPass,
      isNonRoundtrip,
      isAssignedUser,
      completedByTitle,
      showCompletedByUser,
      showAutoPass,
      showCheckIn,
      showSystemEndedCompletedAt,
      showChildApprovedByUser,
      showChildAutoPassApprovedBy,
      showChildKioskApprovedBy,
      showChildApprovedAtSystemEnded,
      showChildCompletedByUser,
      showChildAutoPassCompleted,
      showChildCompletedBySystemEnded
    }
  }
}
</script>
