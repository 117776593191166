<template>
  <div v-if="!isDashboardDataLoading" class="d-flex flex-column gap-2">
    <VisitorErrorHandler
      v-if="dashboardDataFetchError !== null"
      :error="dashboardDataFetchError"
      @done="dashboardDataFetchError = null"
    />
    <DashboardCounters />
    <RoomStats v-if="['admin', 'staff', 'teacher'].includes(userRole)" />
    <Banners />
    <div class="d-flex justify-content-end px-4 py-2">
      <HelpCenterButton content-key="dashboard_overview" :is-old="true" />
    </div>
    <DashboardDataTable />
  </div>
  <CRow
    v-else
    class="justify-content-center align-content-center mt-4 rounded-30 p-5 d-flex gap-2"
  >
    <CSpinner color="primary" />
  </CRow>
</template>

<script>
import { computed, onMounted, ref } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index"
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import RoomStats from "@/v3components/Dashboard/RoomStatsOld.vue"
import DashboardDataTable from "@/v3components/Datatables/DashboardDataTable/DashboardDataTableOld.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import DashboardCounters from "@/v3components/Dashboard/DashboardCountersOld.vue"
import Banners from "@/v3components/Dashboard/Banners.vue"

export default {
  name: "DashboardOld",
  components: {
    RoomStats,
    HelpCenterButton,
    DashboardCounters,
    DashboardDataTable,
    Banners,
    VisitorErrorHandler
  },

  setup() {
    const store = useStore()
    const isDashboardDataLoading = ref(true)
    const dashboardDataFetchError = ref(null)

    const studentIdCardsLink = computed(() => {
      return store.getters["users/studentIdCardsLink"]
    })

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })

    const isUserUnavailable = computed(() => {
      return store.getters["authentication/isUserUnavailable"]
    })

    const unavailable = computed(() => {
      return store.getters["dashboardTable/unavailable"]
    })

    const passBlock = computed(() => {
      return store.getters["dashboardTable/passBlock"]
    })

    const user = computed(() => {
      return store.getters["authentication/user"]
    })

    const exceededIncidents = computed(() => {
      return store.getters["exceededIncidents/exceededIncidents"]
    })

    const isActiveTardyModule = computed(() => {
      return store.getters["schools/isActiveTardyModule"]
    })

    const itemsBlocked = computed(() => {
      return passBlock.value ? passBlock.value : {}
    })

    const showExceededIncidentsBanner = computed(() => {
      return exceededIncidents.value && userRole.value === "admin"
    })

    const getAllStudentIDCards = async () => {
      await store.dispatch("users/getAllStudentIDCardsPDF")
    }

    onMounted(async () => {
      try {
        await store.dispatch("dashboardTable/getDashboard")
        if (isActiveTardyModule.value && userRole.value === "admin") {
          await store.dispatch("exceededIncidents/getExceededIncidents")
        }
        if (userRole.value === "admin" || userRole.value === "superadmin") {
          await getAllStudentIDCards()
        }
      } catch (error) {
        dashboardDataFetchError.value = error
      } finally {
        isDashboardDataLoading.value = false
      }
    })

    const closeUnavailableAlert = () => {
      if (
        window.confirm("Are you sure you want to delete this Out Of Office?")
      ) {
        endUnavailable(unavailable.value)
      }
    }

    const endUnavailable = (unavailable) => {
      if (unavailable && unavailable.id && unavailable.status) {
        store.dispatch("outOfOfficePasses/updateOutOfOfficeStatus", {
          id: unavailable.id,
          params: { dashboard: true }
        })
      }
    }

    return {
      helpers,
      userRole,
      isUserUnavailable,
      dashboardDataFetchError,
      isDashboardDataLoading,
      unavailable,
      passBlock,
      user,
      exceededIncidents,
      isActiveTardyModule,
      itemsBlocked,
      showExceededIncidentsBanner,
      closeUnavailableAlert,
      endUnavailable,
      studentIdCardsLink
    }
  }
}
</script>
