<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start">
        <InfoBox
          v-if="state.serverRes"
          class="mt-4 mb-2"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
        <div v-if="showSimpleCancel" class="mb-2">
          <InputField
            data-test-id="stu-cancel-apt-pass-comment"
            v-model="state.form.message"
            class="w-full"
            type="textarea"
            :rows="5"
            max-length="250"
            max-chars="250"
            placeholder="Message..."
            :invalid-feedback="errors.message"
          />
          <InfoBox class="danger mt-2" v-if="state.serverErrors.cancel_reason">
            {{ state.serverErrors.cancel_reason[0] }}
          </InfoBox>

          <div class="form-group form-actions mt-4 text-center">
            <div class="d-flex align-items-center justify-content-center">
              <div
                data-test-id="stu-cancel-apt-pass-btn-submit"
                @click="submit()"
              >
                <LoadingButton
                  :is-loading="state.isLoading"
                  class="me-2"
                  solid
                  rounded
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="px-5">
          <div v-if="isBeyond">
            <p class="text-center">
              There may be instances of this series already scheduled on the
              "Next 7 Days" tab. Do you want to cancel all of those as well?
            </p>
            <div class="d-flex justify-content-center mb-3">
              <button
                class="btn btn-cancel no-icon me-2"
                @click="cancelRecurrencePass(1, pass)"
              >
                Just Cancel Future Occurrences
              </button>
              <button
                class="btn btn-edit no-icon"
                @click="cancelRecurrencePass(2, pass)"
              >
                Cancel Current Week And Future Occurrences
              </button>
            </div>
          </div>

          <div v-else>
            <p class="text-center">
              Do you want to delete only this instance or the entire series?
            </p>
            <div class="d-flex justify-content-center mb-3">
              <button
                class="btn btn-cancel no-icon me-2 px-3 py-2"
                @click="cancelPass(pass)"
              >
                Delete only this instance
              </button>
              <button
                class="btn btn-edit no-icon px-3 py-2"
                @click="cancelRecurrencePass(2, pass)"
              >
                Delete entire series
              </button>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { helpers, required } from "@vuelidate/validators"
import { reactive, computed } from "vue"
import { useStore } from "vuex"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import useVuelidate from "@vuelidate/core"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"

export default {
  name: "AptPassCancelation",
  components: {
    InfoBox,
    LoadingButton,
    InputField
  },
  props: [
    "pass",
    "close",
    "countType",
    "isBeyond",
    "onlySimpleCancelation",
    "cancel"
  ],
  emits: ["cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isLoading: false,
      form: {
        message: ""
      },
      serverErrors: {},
      serverRes: null
    })

    const currentUser = computed(() => store.getters["authentication/user"])
    const adultActiveTab = computed(
      () => store.getters["adultAptPass/activeTab"]
    )
    const studentActiveTab = computed(
      () => store.getters["studentAptPass/activeTab"]
    )
    const activeTab = computed(() => {
      return currentUser.value.role.name === "student"
        ? studentActiveTab.value
        : adultActiveTab.value
    })
    const showSimpleCancel = computed(() => {
      return (
        props.onlySimpleCancelation ||
        (!props.pass.is_recurrence && !props.pass.recurrence_appointment_pass)
      )
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        message: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const submit = () => {
      setResponseInfoBox()

      if (isValid.value) {
        const data = {
          id: props.pass.id,
          message: {
            cancel_reason: state.form.message
          }
        }
        state.isLoading = true
        if (currentUser.value.role.name == "student") {
          store
            .dispatch("studentAptPass/cancelAppointmentPassAsStudent", data)
            .then(() => {
              resetForm()
              setResponseInfoBox("Success", "Successfully canceled.")
              setTimeout(() => {
                closeModal()
              }, 1800)
              state.serverErrors = {}
              state.isLoading = false
            })
            .catch((err) => {
              if (err && err.response) {
                const response = err.response.data
                state.serverErrors = response.errors ? response.errors : {}
                setResponseInfoBox("Error", response.message)
              }
              state.isLoading = false
            })
        } else {
          store
            .dispatch("adultAptPass/cancelAppointmentPass", data)
            .then(() => {
              resetForm()
              state.serverErrors = {}
              setResponseInfoBox("Success", "Successfully canceled.")
              state.isLoading = false
              setTimeout(() => {
                closeModal()
              }, 1800)
            })
            .catch((err) => {
              if (err && err.response) {
                const response = err.response.data
                state.serverErrors = response.errors ? response.errors : {}
                setResponseInfoBox("Error", response.message)
              }
              state.isLoading = false
            })
        }
      } else {
        v$.value.$touch()
      }
    }

    const resetForm = () => {
      v$.value.$reset()
      state.form = {
        message: ""
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const refreshForm = () => {
      resetForm()
      setResponseInfoBox()
    }

    const cancelRecurrencePass = (action) => {
      setResponseInfoBox()
      const id =
        props.pass.recurrence_appointment_pass.recurrence_appointment_pass_id

      state.isLoading = true
      store
        .dispatch("adultAptPass/cancelRecurrenceAppointmentPass", {
          id,
          action
        })
        .then(() => {
          state.isLoading = false
          state.serverErrors = {}
          setTimeout(() => {
            closeModal()
          }, 1800)
        })
        .catch((err) => {
          if (err && err.response) {
            const response = err.response.data
            state.serverErrors = response.errors ? response.errors : {}
            setResponseInfoBox("Error", response.message)
          }
          state.isLoading = false
        })
    }

    const cancelPass = (pass) => {
      setResponseInfoBox()
      state.isLoading = true
      store
        .dispatch("adultAptPass/cancelAppointmentPass", {
          id: pass.id,
          fromRecurrence: true
        })
        .then(() => {
          state.isLoading = false
          state.serverErrors = {}
          setTimeout(() => {
            closeModal()
          }, 1800)
        })
        .catch((err) => {
          if (err && err.response) {
            const response = err.response.data
            state.serverErrors = response.errors ? response.errors : {}
            setResponseInfoBox("Error", response.message)
          }
          state.isLoading = false
        })
    }

    const closeModal = () => {
      setResponseInfoBox()
      emit("closeModal")
      emit("cancel")
    }

    return {
      state,
      currentUser,
      adultActiveTab,
      studentActiveTab,
      activeTab,
      showSimpleCancel,
      errors,
      submit,
      resetForm,
      setResponseInfoBox,
      refreshForm,
      cancelRecurrencePass,
      cancelPass,
      closeModal
    }
  }
}
</script>
<style scoped lang="scss">
textarea::-webkit-scrollbar {
  display: none;
}

textarea,
textarea::placeholder {
  font-weight: bold;
  font-size: 12px;
  color: #000 !important;
}

.invalid-feedback {
  font-weight: bold;
  font-size: 12px;
}
</style>
