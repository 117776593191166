<template>
  <CContainer class="datatable-container">
    <Loader :is-processing="state.isLoading" />
    <!-- Start Modals -->
    <!-- Unarchive message -->
    <CModal
      backdrop="static"
      :visible="!!state.showUnarchiveUsersModal"
      :close-on-backdrop="false"
    >
      <template #header-wrapper>
        <div
          class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
        >
          <div
            class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
            @click="
              ;(state.showUnarchiveUsersModal = null), resetResponseMessages()
            "
          >
            <span class="close-icon"></span>
          </div>
        </div>
      </template>
      <div class="px-3 pt-3 pb-1">
        <div>
          {{
            state.reqResponse.message !=
            "Invalid error from CUS, please contact with administrator"
              ? state.reqResponse.message
              : "This change may take 24 hours to execute. Check tomorrow for this request to be updated. If no change, please contact the help desk."
          }}
        </div>
        <div class="text-center mt-3">
          <button
            @click="
              ;(state.showUnarchiveUsersModal = null), resetResponseMessages()
            "
            class="btn p-3 bg-gradient-blue text-white ms-2"
          >
            Okay
          </button>
        </div>
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- End Modals -->
    <CRow class="justify-content-center mt-2">
      <CCol md="11" class="mb-5">
        <h1 class="text-center">Archived Users</h1>
      </CCol>
    </CRow>

    <CRow class="mt-4 justify-content-center">
      <CCol md="11">
        <div
          class="table-tabs-header mb-2 bg-white d-flex align-items-center justify-content-between"
        >
          <div
            v-for="(tab, index) in state.tableTabs"
            :key="index"
            class="table-tab"
            :class="{ active: tab.value === state.activeTableTab.value }"
          >
            {{ tab.label }}
          </div>
          <div class="actions d-flex align-items-center">
            <div
              class="lh-normal cursor-pointer text-accent-blue-light"
              @click="
                handleUsers(state.pagination.activePage, false, false, true)
              "
            >
              <i class="fi flaticon-download me-1"></i>
              <span class="d-none d-md-inline-block">Download CSV</span>
            </div>

            <div
              class="d-flex align-items-center search-field mb-0 ms-2 ms-md-4"
            >
              <CInputGroup class="w-auto">
                <div class="input-group-prepend">
                  <span class="input-group-text lh-normal"
                    ><div><i class="flaticon-magnifying-glass"></i></div
                  ></span>
                </div>
                <CFormInput
                  placeholder="Search"
                  v-model="state.columnSearchKeys.globalQuery"
                  @input="searchByQuery($event.target.value)"
                >
                </CFormInput>
              </CInputGroup>
            </div>
          </div>
        </div>
        <div
          class="status-guides-container pt-1 pb-2 d-flex align-items-center"
        >
          <div>
            <div v-if="!provideUsersByApiOnly">
              <div class="mb-2">Please select user(s) to unarchive</div>
              <div class="pl-2 d-flex align-items-center">
                <input
                  type="checkbox"
                  class="me-2"
                  :checked="
                    state.selectedUsers.length === archivedUsers?.length &&
                    archivedUsers.length > 0
                  "
                  @change="selectUsers($event)"
                  ref="selectAllCheckbox"
                />
                <button
                  class="px-2 py-2 bg-gradient-blue reverse text-white text-uppercase fw-bold"
                  id="archived-users-custom-btn"
                  @click="unArchiveBulk"
                >
                  Unarchive users
                </button>
              </div>
            </div>
            <CCheckBox
              label="Student Number"
              :model-value="state.filterParams.student_sis_id"
              @change="changeSNFilter()"
              name="Student number"
              class="custom-checkbox"
              :inline="true"
              :custom="true"
            />
          </div>
          <per-page-selector
            :action="setPerPage"
            :pagination="state.pagination"
          />
        </div>

        <!-- End of modals -->
        <CAlert
          v-if="state.reqResponse.message"
          class="mt-1 w-100"
          :color="state.reqResponse.type"
          :show="!!state.reqResponse.message"
        >
          {{ state.reqResponse.message }}
        </CAlert>

        <DataTable
          v-if="archivedUsers"
          class="datatable remove-first-th-border bg-white"
          :items="archivedUsers"
          :fields="activeDataFields"
          :sorter="{ external: true }"
          column-filter
          @update:sorter-value="sortByColumn"
        >
          <template #first_name-filter>
            <input
              v-model="state.columnSearchKeys.first_name.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #last_name-filter>
            <input
              v-model="state.columnSearchKeys.last_name.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #email-filter>
            <input
              v-model="state.columnSearchKeys.email.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #role_id-filter>
            <input
              v-model="state.columnSearchKeys.role_id.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #created_at-filter>
            <input
              v-model="state.columnSearchKeys.created_at.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #archived_at-filter>
            <input
              v-model="state.columnSearchKeys.archived_at.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #multiselect="{ item }">
            <td class="mw-auto">
              <input
                v-if="!provideUsersByApiOnly"
                v-model="state.selectedUsers"
                type="checkbox"
                :value="item.id"
              />
            </td>
          </template>

          <template #new>
            <td>
              <span
                class="px-3 py-2 rounded bg-gradient-blue text-white font-weight-bold"
                >Added</span
              >
            </td>
          </template>

          <template #student_sis_id="{ item }">
            <td>
              {{ item.student_sis_id ? item.student_sis_id : "N/A" }}
            </td>
          </template>

          <template #first_name="{ item }">
            <td>{{ item.first_name }}</td>
          </template>

          <template #last_name="{ item }">
            <td>{{ item.last_name }}</td>
          </template>

          <template #email="{ item }">
            <td>{{ item.email }}</td>
          </template>

          <template #role_id="{ item }">
            <td>
              {{
                item.current_role_in_school
                  ? item.current_role_in_school.name
                  : "N/A"
              }}
            </td>
          </template>

          <template #created_at="{ item }">
            <td>
              <div
                v-html="
                  item.created_at
                    ? $helpers.getHTMLDateTime(
                        item.created_at,
                        'MM/DD/YYYY',
                        'h:mm A',
                        '</br>'
                      )
                    : ''
                "
              ></div>
            </td>
          </template>

          <template #archived_at="{ item }">
            <td>
              <div
                v-html="
                  item.archived_at
                    ? $helpers.getHTMLDateTime(
                        item.archived_at,
                        'MM/DD/YYYY',
                        'h:mm A',
                        '</br>'
                      )
                    : ''
                "
              />
            </td>
          </template>

          <template #action="{ item }">
            <td>
              <button
                v-if="!provideUsersByApiOnly"
                class="px-3 py-1 btn btn-unarchive d-flex align-items-center border-0"
                @click="unArchive(item)"
              >
                <span class="flaticon-back-arrow"></span>
                <span class="ml-1 text-uppercase font-weight-bold"
                  >Unarchive</span
                >
              </button>
            </td>
          </template>
        </DataTable>

        <div v-else class="bg-white p-5 text-center text-dark">
          No Available Items.
        </div>

        <Pagination
          v-if="
            state.pagination.pages &&
            state.pagination.pages > 1 &&
            !state.isLazyLoadingMode
          "
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          :active-page="state.pagination.activePage"
          :pages="state.pagination.pages"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        >
          <template #last-button>
            <i class="flaticon-right-arrow-1"></i>
          </template>
          <template #first-button>
            <i class="flaticon-left-arrow-2"></i>
          </template>
          <template #previous-button>
            <i class="fi flaticon-left-arrow-1"></i>
          </template>
          <template #next-button>
            <i class="fi flaticon-chevron"></i>
          </template>
        </Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { reactive, computed, onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import PerPageSelector from "@/v3components/PerPageSelector.vue"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "ArchivedUsersDataTable",
  components: {
    Loader,
    PerPageSelector
  },
  setup() {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      isLazyLoadingMode: false,
      selectedUsers: [],
      showUnarchiveUsersModal: null,
      bulkUnarchiveSuccessMessage:
        "Your request to bulk unarchive has begun. If the action can be executed users will move from the Archived User page to the Main Users page. This will take some time to complete.",
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      tableTabs: [
        {
          label: "Archived Users",
          value: "archived_users"
        }
      ],
      activeTableTab: {
        value: "archived_users"
      },
      filterParams: {
        sort: "",
        search_query: "",
        student_sis_id: false
      },
      columnSearchKeys: {
        globalQuery: "",
        first_name: { value: "", column: ["first_name"], sortBy: "first_name" },
        last_name: { value: "", column: ["last_name"], sortBy: "last_name" },
        email: { value: "", column: ["email"], sortBy: "email" },
        role_id: {
          value: "",
          column: ["role_user.name"],
          sortBy: "role_user.name"
        },
        created_at: {
          value: "",
          column: ["created_at_text"],
          sortBy: "created_at"
        },
        archived_at: {
          value: "",
          column: ["role_user.pivot.archived_at_text"],
          sortBy: "role_user.pivot.archived_at"
        }
      },
      dataTableFields: [
        { key: "multiselect", label: "", sorter: false, filter: false },
        { key: "new", label: "New", filter: false, sorter: false },
        {
          key: "student_sis_id",
          filter: false,
          sorter: false,
          label: "Student number",
          _style: "min-width:150px"
        },
        { key: "first_name", _style: "min-width:120px", label: "First Name" },
        { key: "last_name", _style: "min-width:120px", label: "Last Name" },
        { key: "email", label: "Email" },
        { key: "role_id", label: "Role" },
        { key: "created_at", _style: "min-width:140px", label: "Added Date" },
        {
          key: "archived_at",
          _style: "min-width:150px",
          label: "Archived Date"
        },
        { key: "action", label: "Action", sorter: false, filter: false }
      ]
    })

    const selectAllCheckbox = ref(null)

    const user = computed(() => store.getters["authentication/user"])

    const users = computed(() => store.getters["users/archivedUsers"])

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const activeDataFields = computed(() => {
      return state.dataTableFields.filter((field) =>
        !state.filterParams.student_sis_id
          ? field.key !== "student_sis_id"
          : field
      )
    })

    const archivedUsers = computed(() => {
      return users.value
    })

    const provideUsersByApiOnly = computed(() => {
      return (
        activeSchool.value && activeSchool.value.provide_users_by_api_only == 1
      )
    })

    onMounted(() => {
      initLazyLoading()
      handleUsers()
    })

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            users.value &&
            users.value.length &&
            state.pagination.total > users.value.length
          ) {
            if (body.offsetHeight + body.scrollTop >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleUsers(state.pagination.activePage, false, true)
            }
          }
        }
      }
    }

    const handleUsers = (page, noLoader, isLazyLoadingMode, isExport) => {
      state.pagination.activePage = page ? page : 1
      state.isLoading = !noLoader
      const params = {
        archived: true,
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.student_sis_id) {
        params.student_sis_id = state.filterParams.student_sis_id
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (isExport) {
        exportUsers(params)
      } else {
        getUsers(params, isLazyLoadingMode)
      }
    }

    const getUsers = (params, isLazyLoadingMode) => {
      store
        .dispatch("users/getUsers", params)
        .then((response) => {
          const data = response.data
          if (isLazyLoadingMode) {
            store.commit("users/PUSH_USERS", data.data)
          } else {
            store.commit("users/SET_USERS", data.data)
          }
          const meta = response.data.meta
          if (meta) {
            state.pagination.total = meta.total
            state.pagination.from = meta.from
            state.pagination.to = meta.to
            state.pagination.pages = Math.ceil(meta.total / meta.per_page)
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  if (
                    col == "role_user.name" ||
                    col == "role_user.pivot.archived_at"
                  ) {
                    state.filterParams.search_query =
                      state.filterParams.search_query +
                      `role_user.pivot.school_id:${user.value.school_id}, `
                  }
                  const searchValue =
                    col === "overlap_time_count"
                      ? columnData.value
                      : '"' + columnData.value + '"'
                  state.filterParams.search_query =
                    state.filterParams.search_query + `${col}:${searchValue}, `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? `"${query}"` : query
        }
        handleUsers()
      }
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        first_name: { value: "", column: ["first_name"], sortBy: "first_name" },
        last_name: { value: "", column: ["last_name"], sortBy: "last_name" },
        email: { value: "", column: ["email"], sortBy: "email" },
        role_id: {
          value: "",
          column: ["role_user.name"],
          sortBy: "role_user.name"
        },
        created_at: {
          value: "",
          column: ["created_at_text"],
          sortBy: "created_at"
        },
        archived_at: {
          value: "",
          column: ["role_user.pivot.archived_at_text"],
          sortBy: "role_user.pivot.archived_at"
        }
      }
    }

    const selectUsers = (e) => {
      if (e.target.checked) {
        state.selectedUsers = []
        users.value.forEach((user) => {
          state.selectedUsers.push(user.id)
        })
      } else {
        state.selectedUsers = []
      }
    }

    const unArchive = (user) => {
      state.isLoading = true
      store
        .dispatch("users/unArchive", user)
        .then(() => {
          setTimeout(() => {
            handleUsers(1, true)
            setSuccessResponse("Successfully unarchived!")
            state.selectedUsers = []
            state.isLoading = false
            state.showUnarchiveUsersModal = true
          }, 800)
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
          state.showUnarchiveUsersModal = true
        })
    }

    const unArchiveBulk = () => {
      state.isLoading = true
      store
        .dispatch("users/unArchiveBulk", { users: state.selectedUsers.join() })
        .then(() => {
          setTimeout(() => {
            handleUsers(1, true)
            setSuccessResponse(state.bulkUnarchiveSuccessMessage)
            state.selectedUsers = []
            state.showUnarchiveUsersModal = true
            state.isLoading = false
          }, 800)
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
          state.showUnarchiveUsersModal = true
        })
    }

    const exportUsers = (params) => {
      state.isLoading = true
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("users/exportUsers", {
          ...params,
          is_archived: true,
          student_sis_id: true
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "archived_users")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handleUsers()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`

      handleUsers()
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleUsers(state.pagination.activePage)
      const topElement = document.getElementsByClassName(
        "datatable-container"
      )[0]
      topElement.scrollIntoView({ behavior: "smooth" })
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const changeSNFilter = () => {
      state.filterParams.student_sis_id = !state.filterParams.student_sis_id
      handleUsers(state.pagination.activePage)
    }

    watch(
      () => users.value,
      () => {
        if (selectAllCheckbox.value && selectAllCheckbox.value.checked) {
          selectUsers({ target: { checked: true } })
        }
      }
    )

    return {
      state,
      archivedUsers,
      selectAllCheckbox,
      activeDataFields,
      provideUsersByApiOnly,
      unArchive,
      unArchiveBulk,
      setPerPage,
      sortByColumn,
      selectUsers,
      setActivePage,
      handleUsers,
      resetResponseMessages,
      searchByQuery,
      changeSNFilter
    }
  }
}
</script>
