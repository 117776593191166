<template>
  <div
    v-if="pass.user"
    class="p-0 position-relative td-box d-table-cell pass-history-modal-cell"
    :class="[passStatus && passStatus.class ? passStatus.class : 'no-target']"
    ref="root"
  >
    <!-- Edit pass time modal -->
    <CModal
      backdrop="static"
      :visible="state.modal.isVisible.passTimeChange"
      :close-on-backdrop="false"
      class="no-target"
    >
      <div class="pt-4 px-4 create-popup-header">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="closeModal()"
        >
          <span class="close-icon no-target"></span>
        </div>
      </div>
      <div v-if="state.modal.activePass">
        <ChangePassHistoryTimeForm
          :pass="state.modal.activePass"
          @pass-time-change="onPassTimeChange"
        />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- Student passes history -->
    <CModal
      backdrop="static"
      v-if="state.modal.activePass"
      :visible="state.modal.isVisible.todayPasses"
      :close-on-backdrop="false"
      class="no-target"
      size="lg"
      add-content-classes="no-padding-modal no-border-radius-modal left-top-header"
    >
      <div class="pt-4 px-3 create-popup-header">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="closeModal()"
        >
          <span class="close-icon no-target"></span>
        </div>
      </div>
      <div>
        <StudentPassHistory
          ref="todayPasses"
          :user-id="state.modal.activePass.user.id"
        />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>

    <div
      class="d-flex align-items-center justify-content-start user-details-column border-0"
      :class="[
        passStatus && passStatus.class ? passStatus.class : '',
        isBasicView ? 'p-2' : ''
      ]"
    >
      <div v-if="passStatus">
        <div
          class="pass-status"
          data-test-id="dashboard-row-pass-status"
          :class="passStatus && passStatus.class ? passStatus.class : ''"
        >
          {{ passStatus.label }}
        </div>
      </div>
      <div
        v-if="!isAppoinment"
        class="edit me-2 no-target"
        :class="{
          'mb-4': !isBasicView && showPassLateBadges
        }"
      >
        <div
          class="d-flex align-items-center content-center mb-1 no-target"
          :class="{
            'mb-4': isBasicView && showPassLateBadges
          }"
        >
          <span
            v-if="isPassEditable"
            class="edit-btn no-target"
            @click="showModal('passTimeChange')"
          >
            <i class="fi flaticon-edit me-1 no-target"></i>
          </span>
          <span
            v-if="getFlagStatusColor"
            class="status-flag"
            :style="'color:' + getFlagStatusColor"
            ><i class="flaticon-flag-2"></i
          ></span>
        </div>
        <p v-show="pass.edited_at" class="is-edited m-0 mt-2">
          EDITED<i class="fi flaticon-edit ms-1"></i>
        </p>
      </div>
      <div
        v-show="!isBasicView && showStudentPhotos"
        class="c-avatar-dashboard me-2"
      >
        <img
          :src="
            pass.user.avatar ? pass.user.avatar : 'img/avatars/user-avatar.jpeg'
          "
          class="c-avatar-dashboard-img"
        />
      </div>
      <div class="user-info ms-2">
        <b data-test-id="dashboard-row-pass-names">{{
          pass.user.first_name + " " + pass.user.last_name
        }}</b>
        <div v-show="!isBasicView">
          <div v-if="isAppoinment" class="d-flex">
            <div v-show="pass.acknowledge_at" class="like-icon"></div>
            <div v-show="pass.acknowledged_by_mail_at" class="like-icon"></div>
          </div>
          <p v-else class="m-0 passes-stat no-target">
            has
            <b
              class="passes-count-badge no-target"
              @click="openStudentHistory()"
              >{{
                pass.user.passes_for_today_count
                  ? pass.user.passes_for_today_count
                  : 0
              }}</b
            >
            passes today
          </p>
        </div>
        <p
          v-show="!pass.edited_at && isBasicView"
          class="is-edited only-basic-view m-0 mt-2"
        >
          EDITED<i class="fi flaticon-edit ms-1"></i>
        </p>
        <p
          v-if="pass.edited_at && isAppoinment"
          class="is-edited d-inline-block m-0 mt-1"
        >
          EDITED<i class="fi flaticon-edit ms-1"></i>
        </p>
        <div v-if="showPassLateBadges" class="pass-bottom-left-badges">
          <div
            v-if="isPassLateToSchool"
            class="late-to-school-badge"
            title="Late To School"
          >
            <i class="ri-rest-time-line"></i>
          </div>

          <div
            v-if="isPassLateToClass"
            class="late-to-class-badge"
            title="Late To Class"
          >
            <i class="ri-alarm-line"></i>
          </div>
        </div>
        <div v-if="isPassLimitExceeded" class="pass-bottom-right-badges">
          <div class="limit-exceeded-badge" title="Pass Limit Exceeded">
            <i class="ri-arrow-up-double-line"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  computed,
  onMounted,
  onUpdated,
  watch,
  inject,
  ref
} from "vue"
import { useStore } from "vuex"
import StudentPassHistory from "@/v3components/Forms/Students/StudentPassHistory.vue"
import ChangePassHistoryTimeForm from "@/components/Forms/ChangePassHistoryTimeForm.vue"
import moment from "moment-timezone"
import helpers from "@/helpers/index.js"

export default {
  name: "UserColumnData",
  components: {
    StudentPassHistory,
    ChangePassHistoryTimeForm
  },
  props: ["pass", "isAppoinment", "isBasicView"],
  setup(props) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      modal: {
        isVisible: {
          passTimeChange: false,
          todayPasses: false
        },
        activePass: null
      }
    })

    const currentUser = computed(() => store.getters["authentication/user"])

    const passesTimeSettings = computed(
      () => store.getters["passes/passesTimeSettings"]
    )

    const showStudentPhotos = computed(
      () => store.getters["schools/showStudentPhotos"]
    )

    const autoPassLimits = computed(() => store.getters["rooms/autoPassLimits"])

    const globalTime = computed(() => store.getters["layout/globalTime"])

    const passReachedSystemEndedTime = computed(() => {
      if (!props.pass.expire_after) {
        return false
      }

      const approvedAtPlusEndTime = helpers.getPassEndTime(props.pass)

      return globalTime.value && moment().isAfter(approvedAtPlusEndTime)
    })

    const getAppPassStatus = computed(() => {
      let classes = {
        class: "",
        label: ""
      }

      if (props.pass) {
        if (
          !props.pass.confirmed_at &&
          !props.pass.confirmed_by_teacher_at &&
          !props.pass.is_missed_request &&
          !props.pass.is_canceled &&
          !props.pass.is_for_future &&
          !props.pass.is_for_today &&
          !props.pass.is_ended
        ) {
          classes = {
            class: "waiting-confirmation",
            label: "Waiting Confirmation"
          }
        }
        if (props.pass.is_canceled) {
          classes = {
            class: "canceled",
            label: "Canceled"
          }
        }
        if (props.pass.is_activated) {
          return {
            class: "activated",
            label: "Activated"
          }
        }
        if (props.pass.is_for_future) {
          classes = {
            class: "future-apt",
            label: "Future Apt"
          }
        }
        if (props.pass.is_for_today) {
          classes = {
            class: "today-pass",
            label: "Today's APT"
          }
        }
        if (props.pass.is_long_running) {
          classes = {
            label: passesTimeSettings.value
              ? helpers.formatDate(
                  passesTimeSettings.value.min_time,
                  "hh:mm:ss",
                  "m"
                ) + "+ MIN"
              : "Min time",
            class: "min-time"
          }
        }

        if (props.pass.is_ended) {
          classes = {
            class: "ended",
            label: "Ended"
          }
        }

        if (props.pass.waiting_activation) {
          classes = {
            class: "wating-activation",
            label: "Waiting activation"
          }
        }
        if (props.pass.is_missed) {
          classes = {
            class: "missed-apt",
            label: "Missed apt"
          }
        }
        if (props.pass.is_missed_request && !props.pass.is_for_future) {
          classes = {
            class: "missed-request",
            label: "Missed request"
          }
        }
        if (props.pass.is_in_another_pass) {
          classes = {
            class: "on-other-pass",
            label: "On other pass"
          }
        }
      }
      return classes
    })

    const getPassStatus = computed(() => {
      const badge_flags = { ...props.pass.badge_flags }
      const nowPlusMinTime = moment(props.pass.approved_at)
      if (passesTimeSettings.value) {
        nowPlusMinTime.add(
          passesTimeSettings.value.min_time.split(":")[0],
          "hours"
        )
        nowPlusMinTime.add(
          passesTimeSettings.value.min_time.split(":")[1],
          "minutes"
        )
        nowPlusMinTime.add(
          passesTimeSettings.value.min_time.split(":")[2],
          "seconds"
        )
      }

      if (moment().isAfter(nowPlusMinTime) && badge_flags.active === true) {
        badge_flags.min_time = true
      } else {
        badge_flags.min_time = false
      }
      let classes = ""

      if (badge_flags) {
        if (badge_flags.active && !badge_flags.min_time) {
          classes = { label: "Active", class: "active" }
        }
        if (badge_flags.canceled) {
          classes = { label: "Canceled", class: "canceled" }
        }
        if (badge_flags.ended) {
          classes = { label: "Ended", class: "ended" }
        }
        if (badge_flags.system_completed) {
          classes = { label: "Ended", class: "ended" }
        }

        if (badge_flags.min_time) {
          classes = {
            label: passesTimeSettings.value
              ? helpers.formatDate(
                  passesTimeSettings.value.min_time,
                  "hh:mm:ss",
                  "m"
                ) + "+ MIN"
              : "Min time",
            class: "min-time"
          }
        }
        if (badge_flags.extended) {
          classes = { label: "Ended", class: "ended" }
        }
        if (badge_flags.missed) {
          classes = { label: "Missed", class: "missed" }
        }
        if (badge_flags.waiting_approval) {
          classes = { label: "Waiting Approval", class: "waiting-approval" }
        }
      }

      return globalTime.value && classes
    })

    const passStatus = computed(() => {
      if (props.isAppoinment) {
        return getAppPassStatus.value
      }
      return getPassStatus.value
    })

    const isPassEditable = computed(() => {
      if (
        passStatus.value &&
        passStatus.value.label != "Waiting Approval" &&
        currentUser.value &&
        props.pass.badge_flags &&
        !props.pass.badge_flags.active
      ) {
        if (
          (props?.pass?.type === "SLP" &&
            props?.pass?.from_type === "App\\Models\\User") ||
          (props?.pass?.type === "SLP" &&
            props?.pass?.from_type === "App\\Models\\Room" &&
            roomIds.value.includes(props?.pass?.from?.id))
        ) {
          return true
        }
        if (props.pass.approved_by === currentUser.value.id) {
          return true
        }
        if (props.pass.completed_by === currentUser.value.id) {
          return true
        }
        if (props.pass.to_id === currentUser.value.id) {
          return true
        }
        if (props.pass.from.id === currentUser.value.id) {
          return true
        }
        if (props.pass.approved_with == "autopass") {
          const autoPassesRooms =
            autoPassLimits.value.auto_pass_preference_to_user.map(
              (el) => el.auto_pass.room_id
            )
          if (autoPassesRooms.includes(props.pass.from.id)) {
            return true
          }
          if (autoPassesRooms.includes(props.pass.to.id)) {
            return true
          }
          return false
        }
      }
      return false
    })

    const getFlagStatusColor = computed(() => {
      if (props.pass.badge_flags) {
        if (props.pass.type === "SLP") {
          return "transparent"
        }
        if (props.pass.badge_flags.system_completed) {
          return "#DF3C3B"
        }
        if (props.pass.badge_flags.min_time) {
          return "#F4EA65"
        }
        if (props.pass.badge_flags.extended) {
          return "#672C91"
        }
      }
      return "transparent"
    })

    const isPassLateToSchool = computed(() => {
      return props.pass?.tardy_badge === "LTS"
    })

    const isPassLateToClass = computed(() => {
      return props.pass?.tardy_badge === "LTC"
    })

    const showPassLateBadges = computed(() => {
      return isPassLateToSchool.value || isPassLateToClass.value
    })

    const isPassLimitExceeded = computed(() => {
      return props?.pass?.badge_flags?.limit_exceeded === true
    })

    const userAsignedRooms = computed(
      () => store.getters["rooms/userAssignRooms"]
    )

    const roomIds = computed(() => {
      return userAsignedRooms.value.map((room) => room.room_id)
    })

    onMounted(() => {
      store.dispatch("rooms/getUserAssignRooms")

      if (passReachedSystemEndedTime.value && props.pass.pass_status == 1) {
        markPassAsEnded()
      }
    })

    onUpdated(() => {
      if (passReachedSystemEndedTime.value && props.pass.pass_status == 1) {
        markPassAsEnded()
      }
    })

    watch(
      () => passReachedSystemEndedTime.value,
      (systemEndedTimeReached) => {
        if (systemEndedTimeReached && props.pass.pass_status == 1) {
          markPassAsEnded()
        }
      }
    )

    const markPassAsEnded = () => {
      const updatedPass = JSON.parse(JSON.stringify(props.pass))
      const completeTime = helpers.getPassEndTime(updatedPass)

      if (updatedPass.child) {
        updatedPass.child.pass_status = 0
        updatedPass.child.system_completed = 1
        updatedPass.child.completed_at = completeTime
      } else {
        updatedPass.pass_status = 0
        updatedPass.system_completed = 1
        updatedPass.completed_at = completeTime
      }

      updatedPass.flagged_at = null
      updatedPass.badge_flags = null

      store.commit("dashboardCounters/UPDATE_PASS", updatedPass)
      store.commit("dashboardTable/UPDATE_PASS", updatedPass)
    }

    const root = ref(null)
    const todayPasses = ref(null)

    const showModal = (modal) => {
      state.modal.activePass = props.pass
      setTimeout(() => {
        const elem = root.value.closest(".vue-recycle-scroller__item-view")
        if (elem) {
          elem.setAttribute("comments-modal", true)
        }

        state.modal.isVisible[modal] = true

        if (modal == "todayPasses") {
          nextTick(function () {
            todayPasses.value.getUserPasses(props.pass.user.id)
          })
        }
      }, 100)
    }

    const openStudentHistory = () => {
      modal.open(StudentPassHistory, {
        size: "lg",
        title: "Today's History",
        props: {
          setData: true,
          userId: props.pass.user.id
        },
        help: "todays_history"
      })
    }

    const onPassTimeChange = (pass) => {
      state.modal.isVisible.passTimeChange = false
      store.commit("dashboardTable/UPDATE_PASS", pass)
      store.dispatch("dashboardCounters/getPassesForCounters")
      setTimeout(() => {
        const elem = root.value.closest(".vue-recycle-scroller__item-view")
        if (elem) {
          elem.removeAttribute("comments-modal")
        }
      }, 500)
    }

    const closeModal = () => {
      state.modal = {
        isVisible: {
          passTimeChange: false,
          todayPasses: false
        },
        activePass: null
      }
      setTimeout(() => {
        const elem = root.value.closest(".vue-recycle-scroller__item-view")
        if (elem) {
          elem.removeAttribute("comments-modal")
        }
      }, 500)
    }

    return {
      root,
      todayPasses,
      state,
      passStatus,
      isPassEditable,
      isPassLateToSchool,
      isPassLateToClass,
      showPassLateBadges,
      isPassLimitExceeded,
      showStudentPhotos,
      passReachedSystemEndedTime,
      getFlagStatusColor,
      showModal,
      openStudentHistory,
      onPassTimeChange,
      closeModal
    }
  }
}
</script>

<style lang="scss">
.vue-recycle-scroller__item-wrapper {
  .vue-recycle-scroller__item-view[comments-modal] {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 999999999999999 !important;
    transform: none !important;
    &
      .t-row
      > div:not(.pass-history-modal-cell, .no-target, .ps__child--consume) {
      visibility: hidden;
    }
    & .t-row > div.pass-history-modal-cell {
      visibility: visible !important;
      background: transparent;
      border-color: transparent;
      & > div.user-details-column {
        visibility: hidden !important;
      }
    }
  }
  .modal {
    overflow: visible !important;
  }
}
</style>
