<template>
  <div class="d-flex align-items-center tabs-container">
    <div v-if="activeTab === 'passes'" class="d-flex">
      <CustomSelect
        :close-on-select="true"
        class="width-160-px"
        :searchable="false"
        v-model="state.selectedPassOption"
        :options="passOptions"
        @update:model-value="setActivePassType(state.selectedPassOption.value)"
      ></CustomSelect>
      <CustomSelect
        :close-on-select="true"
        class="ms-1 width-160-px"
        :searchable="false"
        v-model="state.selectedStatusFilter"
        :options="
          showOnlyActivePasses
            ? passStatusFiltersInTransparency
            : passStatusFilters
        "
        @update:model-value="
          setActivePassState(state.selectedStatusFilter.value)
        "
      ></CustomSelect>
    </div>
    <div v-if="activeTab === 'appointments'" class="d-flex">
      <CustomSelect
        :close-on-select="true"
        class="width-310-px"
        :searchable="false"
        v-model="state.selectedAptPassOption"
        :options="
          activeSchoolHasAppoitmentPasses
            ? aptPassOptions
            : withoutAptPassOptions
        "
        @update:model-value="
          setActivePassType(state.selectedAptPassOption.value)
        "
      ></CustomSelect>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import passConstants from "@/constants/passConstants"
import { onMounted, computed, reactive, watch } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardTabs",
  components: {
    CustomSelect
  },
  props: {
    activeTab: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      selectedPassOption: {
        label: "My Passes",
        type: "pass",
        value: "my"
      },
      selectedAptPassOption: {
        label: "Today's Appointments",
        type: "appointments",
        value: "appointments_today"
      },
      selectedStatusFilter: {
        label: "Active",
        value: "active"
      }
    })

    const passOptions = [
      {
        label: "My Passes",
        type: "pass",
        value: "my"
      },
      {
        label: "All Passes",
        type: "pass",
        value: "all"
      }
    ]

    const passStatusFilters = [
      {
        label: "Active",
        value: "active"
      },
      {
        label: "Both",
        value: "both"
      },
      {
        label: "Ended",
        value: "ended"
      }
    ]

    const aptPassOptions = [
      {
        label: "Today's Appointments",
        type: "appointments",
        value: "appointments_today"
      },
      {
        label: "Today's Awaiting Confirmation",
        type: "appointments",
        value: "appointments_awaiting"
      },
      {
        label: "Future Awaiting Confirmation",
        type: "appointments",
        value: "appointments_future_awaiting"
      }
    ]

    const withoutAptPassOptions = [
      {
        label: "Today's Appointments",
        type: "appointments",
        value: "appointments_today"
      }
    ]

    const passStatusFiltersInTransparency = [
      {
        label: "Active",
        value: "active"
      }
    ]

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )
    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )
    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )
    const activePassState = computed(
      () => store.getters["dashboardTable/getStatus"]
    )
    const isUserInTransparency = computed(
      () => store.getters["authentication/isUserInTransparency"]
    )

    const isAppoinmentMode = computed(() =>
      activePassType.value.includes("appointment")
    )
    const showOnlyActivePasses = computed(
      () => activePassType.value === "all" && !isUserInTransparency.value
    )

    const type = computed(() => store.getters["dashboardTable/getType"])

    const status = computed(() => store.getters["dashboardTable/getStatus"])

    onMounted(() => {
      if (status.value) {
        state.selectedStatusFilter = firstLetterToUpperCase(status.value)
      }
      if (type.value === "my" || type.value === "all") {
        state.selectedPassOption =
          type.value === "my" ? passOptions[0] : passOptions[1]
      }
      if (type.value.includes("appointments_")) {
        state.selectedAptPassOption =
          type.value === "appointments_today"
            ? aptPassOptions[0]
            : type.value === "appointments_awaiting"
              ? aptPassOptions[1]
              : aptPassOptions[2]
      }
      if (
        !window.localStorage.getItem(
          passConstants.LOCAL_STORAGE_KEYS.DASHBOARD_PASS_TYPE
        ) ||
        type.value == "appointment" ||
        (!isActiveAppoinmentModule.value &&
          type.value !== "my" &&
          type.value !== "all")
      ) {
        window.localStorage.setItem(
          passConstants.LOCAL_STORAGE_KEYS.DASHBOARD_PASS_TYPE,
          "my"
        )
        store.commit("dashboardTable/UPDATE_TYPE", "my")
      }
    })

    const firstLetterToUpperCase = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const setActivePassType = (typeVal) => {
      store.commit("dashboardTable/UPDATE_TYPE", typeVal)
      //set deafult value to "active" if user is not in transparency
      if (!isUserInTransparency.value && type.value == "all") {
        store.commit("dashboardTable/UPDATE_STATUS", "active")
      }
    }

    const setActivePassState = (status) => {
      store.commit("dashboardTable/UPDATE_STATUS", status)
    }

    watch(
      () => props.activeTab,
      (value) => {
        if (value === "appointments") {
          setActivePassType(state.selectedAptPassOption.value)
        } else {
          setActivePassType(state.selectedPassOption.value)
        }
      }
    )

    watch(
      () => isUserInTransparency.value,
      (userIsInTransparency) => {
        if (!userIsInTransparency) {
          setActivePassState("active")
          state.selectedStatusFilter = {
            label: "Active",
            value: "active"
          }
        }
      }
    )

    return {
      state,
      passOptions,
      isActiveAppoinmentModule,
      activeSchoolHasAppoitmentPasses,
      activePassType,
      activePassState,
      isUserInTransparency,
      isAppoinmentMode,
      showOnlyActivePasses,
      aptPassOptions,
      setActivePassState,
      passStatusFilters,
      passStatusFiltersInTransparency,
      setActivePassType,
      withoutAptPassOptions
    }
  }
}
</script>
