<template>
  <div>
    <b v-if="isKioskUser" class="processed-by"> kiosk </b>
    <b v-else>
      <div v-if="isAutoPass" class="processed-by">Auto Pass</div>
      <div v-else>
        <div v-if="isKioskRoom" class="processed-by">
          <span v-if="isSameRoom" class="processed-by"> kiosk </span>
          <span v-else class="processed-by not-same">kiosk</span>
        </div>
        <div v-if="isKioskUser" class="processed-by">
          <span v-if="isSameUser" class="processed-by"> kiosk </span>
          <span v-else class="processed-by not-same">kiosk</span>
        </div>
      </div>
    </b>
  </div>
</template>

<script>
import { computed } from "vue"

export default {
  props: {
    endedWith: {
      type: String,
      required: true
    },
    toType: {
      type: String,
      required: true
    },
    toKiosk: {
      type: Object,
      required: true
    },
    toId: {
      type: Number,
      required: true
    },
    to: {
      type: Object,
      required: true
    },
    createdByKiosk: {
      type: Object,
      required: true
    },
    from: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isKioskUser = computed(
      () =>
        (props.endedWith !== "autopass" &&
          props.toType === "App\\Models\\User" &&
          !props.toKiosk.room &&
          props.toKiosk.user.id === props.toId) ||
        (props.endedWith !== "autopass" &&
          props.toType === "App\\Models\\Room" &&
          props.toKiosk.room &&
          props.toKiosk.room.id === props.toId &&
          props.to.trip_type !== "Roundtrip") ||
        (props.endedWith !== "autopass" &&
          props.to.trip_type === "Roundtrip" &&
          props.createdByKiosk.id === props.toKiosk.id)
    )

    const isAutoPass = computed(
      () => props.endedWith === "autopass" && !props.toKiosk.room
    )

    const isKioskRoom = computed(
      () => props.toKiosk && props.toKiosk.room && props.toKiosk.user
    )

    const isSameRoom = computed(() => props.from.id === props.toKiosk.room.id)

    const isSameUser = computed(() => props.from.id === props.toKiosk.user.id)

    return {
      isKioskUser,
      isAutoPass,
      isKioskRoom,
      isSameRoom,
      isSameUser
    }
  }
}
</script>
