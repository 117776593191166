<template>
  <div>
    <div
      v-if="
        pass.child &&
        pass.child.to_kiosk &&
        pass.child.approved_with !== 'autopass' &&
        pass.child.ended_with !== 'autopass'
      "
    >
      <b
        v-if="
          (pass.child.to_type == 'App\\Models\\User' &&
            !pass.child.to_kiosk.room &&
            pass.child.to_kiosk.user.id === pass.child.to.id) ||
          (pass.child.to_type == 'App\\Models\\Room' &&
            pass.child.to_kiosk.room &&
            pass.child.to_kiosk.room.id === pass.child.to.id)
        "
        class="processed-by"
        >kiosk</b
      >
      <b v-else class="processed-by not-same">kiosk </b>
    </div>
    <div
      v-if="
        pass &&
        pass.child &&
        pass.child.ended_with &&
        pass.child.ended_with === 'autopass' &&
        pass.child.to_kiosk &&
        pass.child.to_kiosk.room
      "
    >
      <span
        v-if="
          pass.child.to_kiosk &&
          pass.child.to_kiosk.room &&
          pass.child.to_kiosk.room.id === pass.child.to.id
        "
        class="processed-by"
      >
        kiosk
      </span>
      <span v-else class="processed-by not-same">kiosk</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pass: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
