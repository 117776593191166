<template>
  <CRow>
    <div
      class="box"
      :class="{
        active: state.active ? state.active === option.value : ''
      }"
      v-for="(option, index) in allowedOptions"
      :key="index"
      @click="checkModel(option.value)"
    >
      <div>
        <i :class="option.icon"></i>
        <span class="ms-2">{{ option.label }}</span>
      </div>
    </div>
  </CRow>
</template>

<script>
import { reactive, computed, watch } from "vue"

export default {
  name: "TardyRadioGroup",
  props: {
    value: {},
    modelValue: {},
    isAllowedLates: {
      type: Boolean,
      default: false
    },
    allowedLates: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "input"],
  setup(props, { emit }) {
    const state = reactive({
      model: props.modelValue,
      active: props.modelValue ? props.modelValue : ""
    })

    const allowedOptions = computed(() => {
      return !props.isAllowedLates
        ? repeatOptions
        : props.allowedLates.ltc && props.allowedLates.lts
          ? repeatOptions
          : props.allowedLates.lts
            ? [repeatOptions[0]]
            : props.allowedLates.ltc
              ? [repeatOptions[1]]
              : []
    })

    const repeatOptions = [
      {
        icon: "ri-rest-time-line",
        label: "Late to School",
        value: "LTS"
      },
      {
        icon: "ri-alarm-line",
        label: "Late to Class",
        value: "LTC"
      }
    ]

    const changeModel = (event) => {
      state.model = event
      emit("input", state.model)
      emit("update:modelValue", state.model)
    }

    const checkModel = (value) => {
      if (props.disabled) return
      state.active = value === state.active ? "" : value
      changeModel(state.active)
    }

    watch(
      allowedOptions,
      (newOptions) => {
        if (newOptions.length === 1) {
          state.active =
            newOptions[0].value === state.active ? "" : newOptions[0].value
          checkModel(state.active)
        } else {
          state.active = ""
          changeModel(state.active)
        }
      },
      { immediate: true }
    )

    return {
      state,
      allowedOptions,
      props,
      changeModel,
      checkModel
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/_variables";
@import "src/assets/v3scss/_variables";
.box {
  display: flex;
  width: fit-content;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid $gray-lighten-20;
  color: $base-blue;
  margin-left: 12px;
}
.active {
  border: 1px solid $blue-darken-20;
  background: $blue-darken-20;
  color: $white;
}
</style>
