<template>
  <span v-if="pass" class="pass-type-icon">
    <i v-if="icon" :class="icon" />
    <span>{{ pass.type }}</span>
  </span>
</template>

<script>
import { computed } from "vue"
export default {
  name: "PassTypeIcon",
  props: {
    pass: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const icon = computed(() => {
      if (props.pass && props.pass.type) {
        switch (props.pass.type) {
          case "STU":
            return "ri-walk-line"

          case "TCH":
            return "ri-door-open-line"

          case "KSK":
            return "ri-device-line"

          case "APT":
            return "ri-calendar-check-line"

          case "SLP":
            return "ri-hourglass-line"

          default:
            return null
        }
      }
      return null
    })

    return { icon }
  }
}
</script>
